import React from "react";

const FormularioEmail = () => (
  <div>
    <p className="title is-1 has-text-weight-light animated fadeInUp">
      Envíanos un email
    </p>
    <div className="animated fadeInUp">
      <span className="is-size-4">No dudes en decirnos algo</span>
    </div>

    <div className="content animated fadeInUp">
      <div>
        Teléfono:{" "}
        <a href="tel:968072751" className="has-text-primary">
          968 072 751
        </a>
      </div>
      <div>Llámanos entre las 9:00h y las 14:00h, de lunes a viernes.</div>
      <p>
        Email:{" "}
        <a href="mailto:contacto@grupoaristas.com" className="has-text-primary">
          contacto@grupoaristas.com
        </a>
      </p>
    </div>

    <form action="https://formspree.io/contacto@grupoaristas.com" method="POST">
      <input type="hidden" name="_next" value="/contacto" />
      <input
        type="hidden"
        name="_subject"
        value="Nuevo contacto desde la web"
      />
      <input type="hidden" name="_language" value="es" />
      <input type="text" name="_gotcha" style={{ display: "none" }} />

      <div className="content">
        <div className="field animated fadeInUp">
          <label className="label">¿Cómo te llamas?</label>
          <div className="control has-icons-left">
            <span className="icon">
              <i className="far fa-user" />
            </span>
            <input className="input" name="nombre" type="text" />
          </div>
        </div>

        <div className="field animated fadeInUp">
          <label className="label">¿Dónde te llamamos?</label>
          <div className="control has-icons-left">
            <span className="icon">
              <i className="fas fa-phone" />
            </span>
            <input className="input" type="tel" maxLength={9} name="telefono" />
          </div>
        </div>

        <div className="field animated fadeInUp">
          <label className="label">Dinos tu email</label>
          <div className="control has-icons-left">
            <span className="icon">
              <i className="fas fa-at" />
            </span>
            <input className="input" type="email" name="email" />
          </div>
        </div>

        <div className="field animated fadeInUp">
          <label className="label">Cuéntanos...</label>
          <div className="control">
            <textarea className="textarea" name="Mensaje" />
          </div>
        </div>
        <div className="field is-grouped is-grouped-centered">
          <div className="control animated fadeInUp">
            <input
              className="button is-primary is-radiusless"
              type="submit"
              value="Enviar"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
);

export default FormularioEmail;
