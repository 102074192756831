import React from "react";

import { IconContext } from "react-icons";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";

const Siguenos = () => (
  <StaticQuery
    query={graphql`
      query SocialQuerySiguenos {
        site {
          siteMetadata {
            facebook
            instagram
          }
        }
      }
    `}
    render={data => (
      <IconContext.Provider
        value={{ className: "icon is-large has-text-black" }}
      >
        <section className="section">
          <div className="container ">
            <p className="title is-1 has-text-centered">¡Síguenos!</p>
            <p>
              <div className="columns is-centered">
                <span className="column is-narrow">
                  <a
                    href={data.site.siteMetadata.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                </span>
                <span className="column is-narrow">
                  <a
                    href={data.site.siteMetadata.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </span>
              </div>
            </p>
          </div>
        </section>
      </IconContext.Provider>
    )}
  />
);

export default Siguenos;
