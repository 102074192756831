import React from "react";

import "./contacto.scss";

const GoogleMap = () => (
  <div>
    <p className="title is-1 has-text-weight-light">
      Estamos aquí
    </p>
    <div className="google-maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.4944236652227!2d-1.1623662843150966!3d37.942239979729365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd637f10b8ba2d2d%3A0xca35d86633f57461!2sCalle+la+Alberca%2C+24%2C+30120+Murcia!5e0!3m2!1ses!2ses!4v1531725212886"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    </div>
  </div>
);

export default GoogleMap;
