import React from "react";

import GoogleMap from "./componentes/GoogleMap";
import FormularioEmail from "./componentes/FormularioEmail";

const Contacto = ({ children }) => (
  <section>
    <div className="section">
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <GoogleMap />
          </div>
          <div className="column">
            <FormularioEmail />
          </div>
        </div>
      </div>
    </div>
    {/* <div className="hero">
      <Siguenos />
    </div> */}
  </section>
);

export default Contacto;
